import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/dashboard",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/login-otp-verification",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/LogIn/loginOtpVerify")
    ),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    path: "/changePassword",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Change Password/Index")),
  },
  {
    exact: true,
    path: "/forget-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/user-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement")),
  },
  {
    exact: true,
    path: "/view-user-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/userManagement/userDetails")
    ),
  },
  {
    exact: true,
    path: "/content-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/contentManagement")),
  },
  {
    exact: true,
    path: "/view-content",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/viewContent")),
  },
  {
    exact: true,
    path: "/edit-content",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/editContent")),
  },
  {
    exact:true,
    // guard:true,
    path:"/explore-content",
    layout:DashboardLayout,
    component:lazy(() => import("src/views/pages/exploreContent"))
  },
  {
    exact:true,
    // guard:true,
    path:"/view-explore-content",
    layout:DashboardLayout,
    component:lazy(() => import("src/views/pages/exploreContent/viewExploreContent"))
  },
  {
    exact:true,
    // guard:true,
    path:"/edit-explore-content",
    layout:DashboardLayout,
    component:lazy(() => import("src/views/pages/exploreContent/editExploreContent"))
  },
  {
    exact:true,
    pathname:"/add-explore-content",
    layout:DashboardLayout,
    component:lazy(() => import("src/views/pages/exploreContent/addExploreContent"))
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
