const url = "https://nodepune-webapp.mobiloitte.io/api/v1";
export const socketNotification = "";
export const baseUrl = "";

// const user = `${url}/user`;
const admin = `${url}/admin`;
const cms = `${url}/cms`;


const ApiConfig = {
  adminLogin: `${admin}/adminlogin`,
  forgotPassword: `${admin}/forgotPassword`,
  verifyOTP: `${admin}/verifyOtp`,
  resendOTP:`${admin}/resendOtp`,
  resetPassword: `${admin}/resetPassword`,
  changepassword: `${admin}/changePassword`,
  loginOTPVerify: `${admin}/loginOTPVerify`,
  changePasswordSendOTP: `${admin}/changePasswordSendOTP`,
  changePasswordOTPVerify: `${admin}/changePasswordOTPVerify`,
  listUser: `${admin}/userManagement`,
  userDetails: `${admin}/viewUserProfile`,
  blockUnblock: `${admin}/blockUnblockUser`,
  deleteUser: `${admin}/deleteUser`,
  allBanner: `${url}/banner/get-all-banners`,
  addBanner: `${url}/banner/addBanner`,
  editBanner: `${url}/banner/editBanner`,
  deleteBanner: `${url}/banner/deleteBanner`,
  allBannerWeb: `${url}/banner/get-all-banners-of-web`,
  addBannerWeb: `${url}/banner/addbannerOfWeb`,
  editBannerWeb: `${url}/banner/editBannerofWeb`,
  deleteBannerWeb: `${url}/banner/deleteBannerofWeb`,
  editDiscount: `${admin}/updatePlanData`,
  announcementContentList: `${url}/announcement/announcementContentList`,
  viewannouncementContent: `${url}/announcement/viewannouncementContent`,
  editannouncementContent: `${url}/announcement/editannouncementContent`,
  deleteannouncement: `${url}/announcement/deleteannouncement`,
  addannouncementContent: `${url}/announcement/addannouncementContent`,
  discountManagementData: `${url}/buy/getTradingBotDetails`,
  dashboardCount: `${url}/admin/adminDashboard`,
  assetsData: `${admin}/assetsTransactionList`,
  referralData: `${admin}/referralList`,
  assetsTransactionList: `${url}/admin/assetsTransactionList`,
  approveWithdrawReq: `${url}/admin/approveWithdrawReq`,
  viewUserSubscriptionDetails: `${url}/admin/viewUserProfile`,
  viewUserLoginActivity:`${url}/admin/viewUserLoginActivity`,
  viewUserWalletTransactions:`${url}/admin/viewUserWalletTransactions`,
  viewUserConnectedExchanges:`${url}/admin/viewUserConnectedExchanges`,
  getListStatic:`${url}/cms/getCMSContentList`,
  getSingleStaticContent:`${url}/cms/viewCMSContent`,
  editStaticContent:`${url}/cms/editCMSContent`,
  contactUsList:`${url}/admin/contact-us-list`,
  viewContactUs:`${url}/admin/viewContactUs`,
  newsLettersubscriberList:`${url}/admin/newsLettersubscriberList`,
  sendNotificationToAllUser:`${url}/admin/sendNotificationToAllUser`,
  adminDashbordcount:`${url}/admin/adminDashbordcount`,
  viewUserReferralDetails:`${url}/admin/viewUserReferralDetails`,
  listExploreContent:`${admin}/filterExplorerCommunity`,
  createExplorerCommunity:`${admin}/createExplorerCommunity`,
  getSingleExplorer:`${url}/user/getSingleExplorerCommunity`,
  updateExplorerCommunity:`${admin}/updateExplorerCommunity`,
  deleteExplorerData:`${admin}/deleteExplorerCommunity`,
  changePassword:`${admin}/changePassword`,
  addCMSdata:`${admin}/createExplorerCommunity`,
};

export default ApiConfig;

