import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

export default function AppWrapper({ children }) {
  return (
    <Router>
      <App>{children}</App>
    </Router>
  );
}

export function IdleTimerWrapper({ children }) {
  const history = useHistory();
  const location = useLocation();

  const handleOnIdle = (event) => {
    if (location.pathname !== "/" && location.pathname !== "/forget-password" && location.pathname !== "/login-otp-verification" && location.pathname !== "/verify-otp" && location.pathname !== "/reset-password") {
      console.log("user is idle", event);
      console.log("last active", getLastActiveTime());
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("creatturAccessToken");
      toast.error("Session has been expired.");
      history.push("/login");
    }
  };

  const handleOnActive = (event) => {
   if (location.pathname !== "/" && location.pathname !== "/forget-password" && location.pathname !== "/login-otp-verification" && location.pathname !== "/verify-otp" && location.pathname !== "/reset-password") {
      console.log("user is active", event);
      console.log("time remaining", getRemainingTime());
    }
  };

  const handleOnAction = (event) => {
   if (location.pathname !== "/" && location.pathname !== "/forget-password" && location.pathname !== "/login-otp-verification" && location.pathname !== "/verify-otp" && location.pathname !== "/reset-password") {
      // Perform necessary actions on user action
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 minutes
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return <div>{children}</div>;
}
